import React from "react";
import "./About.css";
import { useLocation, Link } from "react-router-dom";
import check from "../../images/check.svg";

const About = () => {
  const location = useLocation();

  return (
    <section className="ozslot-about mg-bot pad">
      {location.pathname === "/about-us" ? (
        <h1 className="ozslot-about__header">About us</h1>
      ) : (
        <h2 className="ozslot-about__header">About us</h2>
      )}
      <div className="ozslot-about__text-container">
        <div className="ozslot-about__text t-22 col-white ozslot-about__part">
          Australia has a vibrant and passionate welcome, which is why the Poker
          Club offers memorable gaming sessions and first class service. Over
          the years we've built a reputation as a club where quality service,
          professionalism and luxury combine to create unrivalled tournaments.
          That's why we're chosen by beginners and experienced players alike. We
          are very grateful for this trust and it is this approach that allows
          us to continue to develop.
        </div>
        <div className="ozslot-about__img ozslot-about__part">
        </div>
        <div className="ozslot-about__left-container ozslot-about__part">
          <p className="ozslot-about__small-header t-24 col-white">
            Our advantages:
          </p>
          <ul className="ozslot-about__list">
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              Multiple tournament formats
            </li>
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              Valuable prizes and large prize pools
            </li>
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              Professional staff
            </li>
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              Impeccable private club reputation
            </li>
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              Luxurious atmosphere
            </li>
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              High standards of security and privacy
            </li>
            <li className="ozslot-about__item t-22">
              <img
                src={check}
                alt="check"
                title="check"
                width="20"
                height="20"
              />
              Premium hotel accommodation
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
