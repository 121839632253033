import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Rules from "../Rules/Rules";
import Tournaments from "../Tournaments/Tournaments";
import Popup from "../Popup/Popup";
import Cookies from "../Cookies/Cookies";
import Privacy from "../Privacy/Privacy";

function App() {
  return (
    <div className="ozslot-page">
      <Header />
      <Popup/>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/tournaments" element={<Tournaments />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
