import React from "react";
import "./Main.css";
import About from "../About/About";
import Rules from "../Rules/Rules";
import Tournaments from "../Tournaments/Tournaments";
import Hotel from "../Hotel/Hotel";
// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="ozslot-main">
      {/* <MainPopup/> */}
      <section className="ozslot-main__cover mg-bot">
        <h1 className="ozslot-main__header col-white">
          Desert Bluff Poker Club(DBPC)
        </h1>
      </section>
      <About />
      <Hotel />
      <Rules />
      <Tournaments />
    </main>
  );
};

export default MainCover;
