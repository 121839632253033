import React from "react";
import { useLocation } from "react-router-dom";
import "./Tournaments.css";

const Tournaments = () => {
  const location = useLocation();
  return (
    <section className="ozslot-tournaments">
      {location.pathname === "/tournaments" ? (
        <h1 className="ozslot-tournaments__header col-white">
          Upcoming Tournaments
        </h1>
      ) : (
        <h2 className="ozslot-tournaments__header col-white">
          Upcoming Tournaments
        </h2>
      )}
      <ul className="ozslot-tournaments__list">
        <li className="ozslot-tournaments__item item-one t-item">
          <p className="ozslot-tournaments__small-header col-white t-24 text-center">
            The Golden Flush
          </p>
          <p className="ozslot-tournaments__text col-white t-18 text-center">
            This tournament is designed for experienced players who understand
            the basic mechanics of the game. The minimum deposit to register is
            $5,000. No Limit Texas Hold'em rules.
          </p>
        </li>
        <li className="ozslot-tournaments__item item-two t-item">
          <p className="ozslot-tournaments__small-header col-white t-24 text-center">
            Outback Poker Rush
          </p>
          <p className="ozslot-tournaments__text col-white t-18 text-center">
            A regular tournament that attracts both beginners and experienced
            players. The minimum deposit is $1000.
          </p>
        </li>
        <li className="ozslot-tournaments__item item-three t-item">
          <p className="ozslot-tournaments__small-header col-white t-24 text-center">
            Queensland Royale
          </p>
          <p className="ozslot-tournaments__text col-white t-18 text-center">
            An exclusive $10,000 buy-in tournament for VIP players. Held in the
            middle of the month, it offers the chance to win big cash prizes.
          </p>
        </li>
        <li className="ozslot-tournaments__item item-four t-item">
          <p className="ozslot-tournaments__small-header col-white t-24 text-center">
            Paradise High Rollers
          </p>
          <p className="ozslot-tournaments__text col-white t-18 text-center">
            The biggest and most luxurious tournament of the month! Held on the
            last weekend of every month. Entry is by invitation only from our
            Poker Club. You are guaranteed prizes, elite guests and a luxurious
            atmosphere.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default Tournaments;
