import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="ozslot-popup-cookie">
        <form className="ozslot-popup__form">
          <p className="ozslot-popup__header-cookie t-24 col-white">
            Cookies
          </p>
          <p className="ozslot-popup__text t-18 col-white">
            We use cookies to improve your experience and analyze site traffic.
            If you continue browsing, we will assume that you accept its use.
            For more information
          </p>
          <div className="ozslot-popup__btn-container">
            <a href="/cookies" className="ozslot-popup__btn-cookie">
              More information
            </a>
            <p
              className="ozslot-popup__btn-cookie ozslot-popup__btn-agree col-white"
              onClick={addPopupCookie}
            >
              Accept
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
