import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="ozslot-header__container pad">
      <div className="ozslot-header__link-container">
        <Link to="/" className="ozslot-header__link">
          Home
        </Link>
        <Link to="/about-us" className="ozslot-header__link">
          About us
        </Link>
        <Link to="/rules" className="ozslot-header__link">
          Rules
        </Link>
        <Link to="/tournaments" className="ozslot-header__link">
          Tournaments
        </Link>
        <Link to="/contacts" className="ozslot-header__link">
          Contacts
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="ozslot-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={() => setIsActive(false)}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="ozslot-header__link" onClick={() => setIsActive(false)}>
              Home
            </Link>
            <Link to="/about-us" className="ozslot-header__link" onClick={() => setIsActive(false)}>
              About us
            </Link>
            <Link to="/rules" className="ozslot-header__link" onClick={() => setIsActive(false)}>
              Rules
            </Link>
            <Link to="/tournaments" className="ozslot-header__link" onClick={() => setIsActive(false)}>
              Tournaments
            </Link>
            <Link to="/contacts" className="ozslot-header__link" onClick={() => setIsActive(false)}>
              Contacts
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
