import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="ozslot-privacy mg-bot pad">
        <h1>Cookie Policy for au-ozslotkings.com</h1>
        <p className="t-18">
          This is the Cookie Policy for au-ozslotkings.com, accessible from
          au-ozslotkings.com
        </p>
        <h2>What Are Cookies</h2>
        <p className="t-18">
          As is common practice with almost all professional websites this site
          uses cookies, which are tiny files that are downloaded to your
          computer, to improve your experience. This page describes what
          information they gather, how we use it and why we sometimes need to
          store these cookies. We will also share how you can prevent these
          cookies from being stored however this may downgrade or 'break'
          certain elements of the sites functionality.
        </p>
        <h2>How We Use Cookies</h2>
        <p className="t-18">
          We use cookies for a variety of reasons detailed below. Unfortunately
          in most cases there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <h2>Disabling Cookies</h2>
        <p className="t-18">
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of the this site.
          Therefore it is recommended that you do not disable cookies.
        </p>
        <h2>The Cookies We Set</h2>
        <p className="t-18">
          Cookies used to configure the site To ensure that you have a good
          experience on this site, we offer a functionality to set your
          preferences for how this site runs when you use it. To remember your
          preferences, we need to set cookies so that this information can be
          recalled whenever you interact with a page that is affected by your
          preferences.
        </p>
        <h2>Third Party Cookies</h2>
        <p className="t-18">
          In some special cases we also use cookies provided by trusted third
          parties. The following section details which third party cookies you
          might encounter through this site.
        </p>
        <p className="t-18">
          This site uses Google Analytics which is one of the most widespread
          and trusted analytics solution on the web for helping us to understand
          how you use the site and ways that we can improve your experience.
          These cookies may track things such as how long you spend on the site
          and the pages that you visit so we can continue to produce engaging
          content.
        </p>
        <p className="t-18">
          For more information on Google Analytics cookies, see the official
          Google Analytics page.
        </p>
        <h2>More Information</h2>
        <p className="t-18">
          Hopefully that has clarified things for you and as was previously
          mentioned if there is something that you aren't sure whether you need
          or not it's usually safer to leave cookies enabled in case it does
          interact with one of the features you use on our site.
        </p>
      </section>
    </main>
  );
};

export default Cookies;
