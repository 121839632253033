import React from "react";
import "./Rules.css";
import { useLocation } from "react-router-dom";
import chip from "../../images/chip.svg";

const Rules = () => {
  const location = useLocation();
  return (
    <section className="ozslot-rules mg-bot pad">
      {location.pathname === "/rules" ? (
        <h1 className="ozslot-rules__header">Rules of the game</h1>
      ) : (
        <h2 className="ozslot-rules__header">Rules of the game</h2>
      )}
      <div className="ozslot-rules__body">
        <p className="ozslot-rules__small-header">
          To maintain a high level of safety and comfort at Desert Bluff Poker
          Club, all members must abide by the following rules:
        </p>
        <ul className="ozslot-rules__list">
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Valuable prizes and large prize pools In order to attract a wide
            audience and make it easy to play, the Poker Club has adopted
            generally accepted poker rules with a 52-card deck and standard
            combinations.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            In order to participate in the tournament, you must make a posted
            deposit, which will be given to you in chips.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            The terms and conditions of the buy-ins and blinds in the tournament
            will be agreed before play begins and will not change until the
            tournament has finished.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Players are allowed to raise an unlimited number of times per round.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Players are prohibited from colluding in advance to achieve common
            goals. Players suspected of collusion will be excluded from the
            game.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Players are required to announce their end and leave the table
            before the start of the hand.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Players are allowed to leave the table during general breaks after
            prior announcement by the dealer.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            There is an experienced croupier at each table to ensure that the
            game is played according to the rules.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            There is a video camera at each table to monitor the proper conduct
            of the game and to ensure that players do not violate the rules of
            the game.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            It is forbidden to communicate at the table about the cards in your
            hand or that you have discarded.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            When folding, the cards must be dealt face down to the dealer.
            Players are not allowed to reveal the secret of the cards they have
            been dealt.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            The standard decision time is 90 seconds, but this may vary from
            tournament to tournament.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            It is forbidden to use mobile phones, tablets or other electronic
            devices that may directly or indirectly affect the outcome of the
            game.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Violation of the above rules may result in disciplinary,
            administrative, financial or criminal liability.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
          <li className="ozslot-rules__item">
            <img src={chip} alt="chip" title="chip" width="50" height="50" />
            Rules are subject to change and any changes will be communicated to
            members.
            <img src={chip} alt="chip" title="chip" width="50" height="50" className="ozslot-rules__none"/>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Rules;
