import React from "react";
import "./Hotel.css";
import stars from "../../images/stars.svg";
import pin from "../../images/pin.svg";
import { Link } from "react-router-dom";

const Hotel = () => {
  return (
    <section className="ozslot-hotel mg-bot">
      <div className="ozslot-hotel__header-container">
        <h2 className="ozslot-hotel__header col-white">
          Sealuxe Surfers Paradise Central Hotel
        </h2>
        <img src={stars} alt="stars" title="stars" width="135" height="27" />
        <p className="ozslot-hotel__adress t-18 col-white">
          <img src={pin} alt="pin" title="pin" width="26" height="26" />9 Ferny
          Ave, Surfers Paradise, QLD, 4217
        </p>
      </div>
      <div className="ozslot-hotel__body">
        <p className="ozslot-hotel__text col-white t-24">
          Located in the heart of the Gold Coast, your stay at the Sealuxe
          Surfers Paradise Central Hotel will give you daily views of the
          Australian outback. All tournament participants are provided with
          first class accommodation in luxurious rooms with panoramic windows
          and ocean views. The staff will provide you with first class service
          and the hotel infrastructure will provide you with an unforgettable
          environment in which to relax and unwind. Each guest can enjoy
          luxurious accommodation, superb cuisine and relaxing spa treatments to
          make their stay not only comfortable but memorable.
        </p>
        <div className="ozslot-hotel__img-conteiner">
          <div className="item item-1"></div>
          <div className="item item-2"></div>
          <div className="item item-3"></div>
          <div className="item item-4"></div>
        </div>
        <div className="ozslot-hotel__list-container">
          <p className="ozslot-hotel__small-header col-white t-24">
            Benefits for tournament participants:
          </p>
          <ul className="ozslot-hotel__list">
            <li className="ozslot-hotel__item">
              Accommodation at the Sealuxe Surfers Paradise Central Hotel.
            </li>
            <li className="ozslot-hotel__item">
              Flights are included in the entry fee for VIP players.
            </li>
            <li className="ozslot-hotel__item">
              Transfer from and to the airport.
            </li>
            <li className="ozslot-hotel__item">VIP pass to all club events.</li>
            <li className="ozslot-hotel__item">
              All inclusive meals including breakfast, lunch and dinner.
            </li>
          </ul>
          <Link to="https://www.expedia.com/Gold-Coast-Hotels-Sealuxe-Central-Surfer-Paradise-Seaview-Deluxe-Spa-Apartment.h24399910.Hotel-Information?" target="_blank" className="ozslot-hotel__link t-22 col-white">Reserve</Link>
        </div>
      </div>
    </section>
  );
};

export default Hotel;
